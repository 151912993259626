/**
 * Load all JS dependencies first.
 */

require('./bootstrap');

// Optional Vue stuff, off for now.

//window.Vue = require('vue');
// Autoregister Vue components
// Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
//const files = require.context('./', true, /\.vue$/i)
//files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))
// Create Vue app
// const app = new Vue({
//     el: '#app',
// });

removeElement = function(el) {
    el.parentNode.removeChild(el);
}
